import axios from "axios";

const redirectGEOLoc = () => {
  axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      let data = response.data;
      console.log({
        ip: data.ip,
        countryName: data.country_name,
        countryCode: data.country_code,
        continentCode: data.continent_code,
        inEU: data.in_eu,
        city: data.city,
        timezone: data.timezone,
        currency: data.currency
      });
      window.location.replace("https://www.ch.galaxyone.app");
    })
    .catch((error) => {
      console.log(error);
    });
};

redirectGEOLoc()
